<template>
  <div class="billList">
    <!-- 账单页 -->
    <div class="hasBilllist" v-if="list.length > 0">
      <div class="hasBill-content">
        <div class="content-item" v-for="item in list" :key="item.id">
          <div class="item-top">
            <span class="appName">{{ item.loanName }}</span>
            <span class="loanDate">借款日:{{ item.gmtCreatedStr }}</span>
            <span class="backing">还款中</span>
          </div>
          <div class="item-bottom">
            <p class="item-bottom-left">
              <span class="loanMoney">{{ item.planAmt }}</span>
              <span class="nowbill">本期账单</span>
            </p>
            <p class="item-bottom-middle">
              <span class="nowStatus" v-if="item.status == 'UNCURRENT'"
                >未到期</span
              >
              <span class="nowStatus" v-if="item.status == 'BACKING'"
                >待还款</span
              >
              <span class="nowStatus2" v-if="item.status == 'OVERDUED'"
                >已逾期</span
              >
              <span class="backDate">{{ item.planDateStr }}</span>
            </p>
            <button class="immediaBack" @click="JumpLink(item)">
              立即还款
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 无账单 -->
    <div class="noBilllist" v-else>
      <div class="nobill-content">
        <img src="./img/bill-bgc.png" alt="" />
        <p>目前还没有账单哦</p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import API from "../../server/api";
import { Jump } from '@/utils/routh';
export default {
  name: "Bill",
  setup() {
    // 定义当前页面的state
    const state = reactive({
      list: [],
      hasNextPage: false,
      desc: "目前还没有账单哦，去借一笔",
    });

    // 页面加载
    onMounted(() => {
      const loanData = localStorage.getItem('loanModelList')
      if(loanData){
        state.list = JSON.parse(loanData);
      }

      init()
    });

    // 初始化
    const init = async ()=>{
      try{
        const res = await API.isUser()
        if(res.success){
          const {loanModelList} = res.data;
          state.list = loanModelList
        }
      }catch (err){
        console.log(err.message)
      }
    }

    //立即还款
    const JumpLink = (item) => {
      Jump(`/billDetail`,{
        loanNo:item.loanNo
      });
    };
    return {
      ...toRefs(state),
      JumpLink
    };
  },
};
</script>

<style lang="less" src='./index.less' scoped></style>
